{
  "lang__home-page-title" :  {
    "ua": "БФ \"ЗАНОВО\".",
    "en": "RENOVATE CHARITY."
  },
  "lang__hero": {
    "ua": "<span class=\"hero__title-primary\">с</span><span class=\"hero__title-primary\">в</span><span class=\"hero__title-primary\">о</span><span class=\"hero__title-primary\">є</span><span class=\"hero__title-primary\">&nbsp;</span><span class=\"hero__title-accent\">з</span><span class=\"hero__title-accent\">а</span><span class=\"hero__title-accent\">х</span><span class=\"hero__title-accent\">и</span><span class=\"hero__title-accent\">с</span><span class=\"hero__title-accent\">т</span><span class=\"hero__title-accent\">и</span><span class=\"hero__title-accent\">м</span><span class=\"hero__title-accent\">о</span><span class=\"hero__title-accent\">,</span><span class=\"hero__title-primary\"><br></span><span class=\"hero__title-accent\">в</span><span class=\"hero__title-accent\">і</span><span class=\"hero__title-accent\">д</span><span class=\"hero__title-accent\">б</span><span class=\"hero__title-accent\">у</span><span class=\"hero__title-accent\">д</span><span class=\"hero__title-accent\">у</span><span class=\"hero__title-accent\">є</span><span class=\"hero__title-accent\">м</span><span class=\"hero__title-accent\">о</span><span class=\"hero__title-primary\">&nbsp;</span><span class=\"hero__title-primary\">з</span><span class=\"hero__title-primary\">а</span><span class=\"hero__title-primary\">н</span><span class=\"hero__title-primary\">о</span><span class=\"hero__title-primary\">в</span><span class=\"hero__title-primary\">о</span><span class=\"hero__title-primary\">!</span>",
    "en": "<span class=\"hero__title-primary\">d</span><span class=\"hero__title-primary\">e</span><span class=\"hero__title-primary\">f</span><span class=\"hero__title-primary\">e</span><span class=\"hero__title-primary\">n</span><span class=\"hero__title-primary\">d</span><span class=\"hero__title-primary\">&nbsp;</span><span class=\"hero__title-accent\">o</span><span class=\"hero__title-accent\">u</span><span class=\"hero__title-accent\">r</span><span class=\"hero__title-accent\">&nbsp;</span><span class=\"hero__title-accent\">o</span><span class=\"hero__title-accent\">w</span><span class=\"hero__title-accent\">n</span><span class=\"hero__title-accent\">,</span><span class=\"hero__title-primary\"><br></span><span class=\"hero__title-accent\">r</span><span class=\"hero__title-accent\">e</span><span class=\"hero__title-accent\">n</span><span class=\"hero__title-accent\">o</span><span class=\"hero__title-accent\">v</span><span class=\"hero__title-accent\">a</span><span class=\"hero__title-accent\">t</span><span class=\"hero__title-accent\">e</span><span class=\"hero__title-primary\">&nbsp;</span><span class=\"hero__title-primary\">a</span><span class=\"hero__title-primary\">n</span><span class=\"hero__title-primary\">e</span><span class=\"hero__title-primary\">w</span><span class=\"hero__title-primary\">!</span>"
  },
  "lang__main-about-text-1" :  {
    "ua": "З лютого 2022 року ми почали надавати допомогу на Київському залізничному вокзалі. Наша команда з понад 100 волонтерів допомагала військовим, біженцям, соціальним центрам і лікарням. Завдяки вашим внескам ми забезпечували гуманітарні вантажі для регіонів, постраждалих від війни, а також надавали харчування сотням людей щодня.",
    "en": "In February 2022, we started providing assistance at the Kyiv railway station. Our team of over 100 volunteers helped the military, refugees, social centers and hospitals. Thanks to your contributions, we have provided humanitarian supplies to war-torn regions and fed hundreds of people every day."
  },
  "lang__main-about-text-2" :  {
    "ua": "Труднощі нас лише зміцнювали, і сьогодні ми, об'єднані в благодійний фонд \"Заново\", продовжуємо працювати за кількома напрямками: відбудова країни, гуманітарна допомога, підтримка ЗСУ, плетіння сіток, виготовлення свічок та організація благодійних заходів. Кожен внесок і кожна година праці наших волонтерів наближає нас до перемоги. Ми віримо у квітуче майбутнє України і разом працюємо задля його досягнення.",
    "en": "Difficulties only strengthened us, and today we, united in the \"RENOVATE\" charity foundation, continue to work in several directions: reconstruction of the country, humanitarian aid, support of the Ukrainian Armed Forces, weaving nets, making candles and organizing charity events. Every contribution and every hour of work of our volunteers advance us toward to victory. We believe in the prosperous future of Ukraine and work together to achieve it."
  },
  "lang__main-icons-title-1" :  {
    "ua": "Допомога ЗСУ",
    "en": "Help to AFU"
  },
  "lang__main-icons-description-1" :  {
    "ua": "Забезпечуємо наших військових необхідним обладнанням, спорядженням та медикаментами. Ми співпрацюємо з волонтерами та партнерами, щоб оперативно доставляти допомогу на передову, підтримуючи захисників у їх боротьбі за мир і безпеку.",
    "en": "We provide our military with the necessary equipment, supplies and medicines. We work with volunteers and partners to quickly deliver aid to the frontlines, supporting defenders in their fight for peace and security."
  },

  "lang__main-icons-title-2" :  {
    "ua": "Відбудова країни",
    "en": "Country reconstruction"
  },
  "lang__main-icons-description-2" :  {
    "ua": "Відновлюємо інфраструктуру, постраждалу від воєнних дій. Залучаємо ресурси та волонтерів для ремонту житлових будинків, шкіл і лікарень, щоб повернути громадам стабільність і комфортне життя.",
    "en": "We are restoring infrastructure damaged by military operations. We are mobilizing resources and volunteers to restore residential buildings, schools and hospitals to restore stability and comfortable living to communities."
  },

  "lang__main-icons-title-3" :  {
    "ua": "Загін сітко-маскувальних сил",
    "en": "Camouflage Forces"
  },
  "lang__main-icons-description-3" :  {
    "ua": "Виготовляємо та постачаємо маскувальні сітки для військових підрозділів. Наші зусилля сприяють підвищенню захисту техніки та особового складу на передовій.",
    "en": "We manufacture and supply camouflage nets for military units. Our efforts contribute to increasing the protection of equipment and personnel on the front lines."
  },

  "lang__main-icons-title-4" :  {
    "ua": "Сили спеціальних свічкових операцій",
    "en": "Candle Forces"
  },
  "lang__main-icons-description-4" :  {
    "ua": "Забезпечуємо військових засобами для світла та тепла під час бойових завдань. Наша підтримка сприяє підвищенню комфорту перебування у важких умовах.",
    "en": "We provide military equipment for light and heat during combat missions. Our support helps increase the comfort of staying in difficult conditions."
  },

  "lang__main-icons-title-5" :  {
    "ua": "Гуманітарна допомога",
    "en": "Humanitarian aid"
  },
  "lang__main-icons-description-5" :  {
    "ua": "Надаємо гуманітарну допомогу постраждалим від воєнних дій. Забезпечуємо продуктами, одягом та необхідними речами, щоб підтримати людей у складних життєвих обставинах.",
    "en": "We provide humanitarian assistance to victims of military operations. We provide food, clothing and necessities to support people in difficult life circumstances."
  },

  "lang__main-icons-title-6" :  {
    "ua": "Благодійні заходи",
    "en": "Charity events"
  },
  "lang__main-icons-description-6" :  {
    "ua": "Організовуємо благодійні заходи для збору коштів та підвищення обізнаності про наші проєкти. Залучаємо громаду до активної участі у благодійності та підтримці потребуючих.",
    "en": "We organize charity events to raise funds and raise awareness of our projects. We involve the community in active participation in charity and support of the needy."
  }
}