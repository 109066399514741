{
  "lang__nets-page-title" :  {
    "ua": "БФ \"ЗАНОВО\". Маскувальні сітки.",
    "en": "RENOVATE CHARITY. Project \"Nets\""
  },
  "lang__nets-title" : {
    "ua": "Загін сітко-маскувальних сил",
    "en": "Squad of Net-Camouflage Forces"
  },
  "lang__nets-description-1" : {
    "ua": "\"Загін Сітко-маскувальних сил\" – окремий напрям нашого фонду. Починали ще на Київському центральному з-д вокзалі з простих сіток кольору \"вся тканина, що у нас є\", а тепер робимо професійні камуфляжні сітки, чохли для шоломів (кавери) та кікімори. Професійно підбираємо колір для маскувальних виробів відповідно порам року.",
    "en": "\"Squad of Net-Camouflage Forces\" is a separate direction of our foundation. We started at the Kyiv central railway station with simple nets in the color of \"all the fabric we have\", and now we make professional camouflage nets, helmet covers (camos), and ghillie suits. We professionally select the color for camouflage products according to the seasons."
  },
  "lang__nets-description-2" : {
    "ua": "З березня 2022 по січень 2024 року виготовили та передали нашим захисникам плетених сіток 126 штук, витинанок – 155 штук. Робота триває! Не зупиняємось! Плетемо до Перемоги!",
    "en": "From March 2022 to January 2024, we made and delivered to our defenders 126 woven nets and 155 cut-out nets. The work continues! We weave until Victory!"
  },
  "lang__nets-description-3" : {
    "ua": "Опанували спанбонд – диво матеріал для маскувальних сіток! Окрім звичайного плетіння на основі сітки, в яку вплітаються смужки тканини, задіяли спеціальний станок для пробивання отворів у спанбонді і почали виготовлення маскувальних сіток – витинанок. За рахунок такої оптимізації виробництва, суттєво збільшено обсяги готової продукції та скорочено час на її виготовлення.",
    "en": "We have mastered spunbond - a miracle material for camouflage nets! Besides the usual weaving based on a grid into which fabric strips are woven, we have engaged a special machine for punching holes in spunbond and started making cut-out camouflage nets. This production optimization has significantly increased the volume of finished products and reduced the manufacturing time."
  },
  "lang__nets-description-4" : {
    "ua": "З вересня 2022 по квітень 2024 тільки на станку виготовили зі спанбонду 20 000 м<sup>2</sup> маскувальних сіток-витинанок різного розміру! Не зупиняємось! Допомагаємо нашим воїнам бути максимально непомітними!",
    "en": "From September 2022 to April 2024, we made 20,000 m<sup>2</sup> of spunbond cut-out camouflage nets of various sizes using the machine! We don't stop! We help our warriors remain as invisible as possible!"
  },
  "lang__nets-description-5" : {
    "ua": "Наш пречудовий Загін сітко-маскувальних сил робить дива: плете велетенські сітки! Це цілий тривалий процес. І найскладніше в ньому – це зшивання всіх частин в одне величезне ціле. Але для наших неймовірних волонтерів немає нічого неможливого!",
    "en": "Our wonderful Squad of Net-Camouflage Forces creates giant nets! This is a long process, and the most challenging part is sewing all the parts into one huge whole. But nothing is impossible for our incredible volunteers!"
  },
  "lang__nets-description-6" : {
    "ua": "З вересня 2022 по січень 2024 виготовлено чотири велетенські маскувальні сітки-витинанки 20x20 м<sup>2</sup>; п’ять сіток 12x6 м<sup>2</sup> (з них: 3 витинанки, 2 плетені); одна сітка плетена 11x6 м<sup>2</sup>; дві витинанки 10x2 м<sup>2</sup> та багато сіток 10x10 м<sup>2</sup>, як витинанок, так і плетених. Робота триває! Плетемо до Перемоги!",
    "en": "From September 2022 to January 2024, we made four giant cut-out nets of 20x20 m<sup>2</sup>; five nets of 12x6 m<sup>2</sup> (three cut-out, two woven); one woven net of 11x6 m<sup>2</sup>; two cut-out nets of 10x2 m<sup>2</sup>; and many nets of 10x10 m<sup>2</sup>, both cut-out and woven. The work continues! We weave until Victory!"
  },
  "lang__nets-description-7" : {
    "ua": "Що таке кікімора? Це супермаскування для розвідників, снайперів і всіх тих, кому потрібно бути максимально непомітним та ефективним у знищенні ворога. Наш Загін сітко-маскувальних сил виробляє найнепомітніші кікімори, які до того ж дуже зносостійкі та зручні у використанні.",
    "en": "What is a ghillie suit? It is super-camouflage for scouts, snipers, and anyone who needs to be as invisible and effective as possible in eliminating the enemy. Our Squad of Net-Camouflage Forces makes the most inconspicuous ghillie suits, which are also very durable and easy to use."
  },
  "lang__nets-description-8" : {
    "ua": "І в цьому нам допомагають супер-дівчата з магазину \"Швейний світ\" (Київ). Вони шиють якісні основи для кікімор, постійно вдосконалюючи їх моделі. Ми дуже цінуємо нашу з ними дружбу та співробітництво! Дякуємо що йдемо до Перемоги разом! До січня 2024 року вже виготовлено та передано нашим захисникам 302 штуки кікімор. Робота триває! Не зупиняємось!",
    "en": "Super-girls from the \"Sewing World\" store (Kyiv) help us in this. They sew quality bases for ghillie suits, constantly improving their models. We highly value our friendship and cooperation with them! Thank you for moving towards Victory together! As of January 2024, 302 ghillie suits have been made and delivered to our defenders. The work continues! We don't stop!"
  },
  "lang__nets-description-9" : {
    "ua": "Ще один вид маскування: чохли (кавери), які надягаються на шоломи військових та допомагають воїнам бути непомітними для ворога.",
    "en": "Another type of camouflage: covers (camos) worn over military helmets to help soldiers remain invisible to the enemy."
  },
  "lang__nets-description-10" : {
    "ua": "До січня 2024 року вже виготовлено та передано нашим захисникам 676 штук таких каверів. Не зупиняємось! Продовжуємо забезпечувати наших захисників необхідним маскуванням!",
    "en": "As of January 2024, 676 covers have been made and delivered to our defenders. We don't stop! We continue to provide our defenders with essential camouflage!"
  },
  "lang__nets-presentation-1" : {
    "ua": "Представляємо нашу презентацію \"Маскувальні сітки зі спанбонду лазерною порізкою для ЗСУ\", яка розповідає що таке спанбонд та про основні етапи виготовлення маскувальних сіток-витинанок.",
    "en": "We present our presentation \"Spunbond Laser-Cut Camouflage Nets for the Armed Forces of Ukraine\", which explains what spunbond is and the main stages of making cut-out camouflage nets."
  },
  "lang__nets-presentation-2" : {
    "ua": "Подивитись презентацію",
    "en": "View Presentation"
  }
}