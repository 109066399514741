{
  "lang__zsu-page-title" :  {
    "ua": "БФ \"ЗАНОВО\". Допомога ЗСУ.",
    "en": "RENOVATE CHARITY. Project \"Help to AFU\""
  },
  "lang__zsu-title" : {
    "ua": "Допомога ЗСУ",
    "en": "Help to AFU"
  },
  "lang__zsu-list-title-1" : {
    "ua": "Повсякденні потреби",
    "en": "Support for the Military"
  },
  "lang__zsu-list-description-1" : {
    "ua": "Постійно підтримуємо наших військових на різних напрямках фронту для покращення їхнього буття та знаходимо для них все необхідне: забезпечуємо наших захисників продуктами харчування (тушонка, сухі типи каш і супів швидкого приготування, солодкі батончики та шоколад, консервовані продукти харчування), таблетками для знезараження води, засобами гігієни, термосами, пляшками для води, посудом, термобілизною, взуттям, спальниками, карематами, наметами, ліхтариками, павербанками, генераторами, акумуляторами, старлінками та іншим обладнанням, яке допомагає воїнам захищати нашу країну максимально ефективно.",
    "en": "We constantly support our military across various frontlines to improve their living conditions and meet their needs. We provide our defenders with food (canned meat, instant cereals and soups, sweet bars and chocolate, canned food), water purification tablets, hygiene products, thermoses, water bottles, dishes, thermal underwear, shoes, sleeping bags, mats, tents, flashlights, power banks, generators, batteries, Starlinks, and other essential equipment to help them defend our country as effectively as possible."
  },
  "lang__zsu-list-title-2" : {
    "ua": "Облаштування побуту",
    "en": "Living Conditions"
  },
  "lang__zsu-list-description-2" : {
    "ua": "Закуповуємо будівельні матеріали для покращення умов проживання наших воїнів в окопах. Завдяки плитам ДСП, оцинкованій сітці, скобам, цвяхам, саморізам, будівельним кріпленням, пилам, сокирам, лопатам та іншим буд. матеріалам, а також насосам для відкачування води наші захисники мають можливість добре облаштовувати свої бліндажі та підготуватись до будь-яких змін погоди.",
    "en": "We purchase building materials to improve the living conditions of our soldiers in trenches. Thanks to chipboard, galvanized mesh, staples, nails, screws, building fasteners, saws, axes, shovels, and other building materials, as well as water pumps, our defenders can better equip their bunkers and prepare for any weather changes."
  },
  "lang__zsu-list-title-3" : {
    "ua": "Забезпечення медициною",
    "en": "Medical Supplies"
  },
  "lang__zsu-list-description-3" : {
    "ua": "Особлива увага приділяється забезпеченню медициною наших воїнів: фіз. розчини, знеболювальні, етиловий спирт, адреналін, ізофілія, серцево-судинні та кишково-шлункові таблетки, вітаміни, зігріваючі гелі та мазі, антисептичні мазі та розчини. Крім роздрібної медицини також надаємо укомплектовані аптечки за військовим стандартом країн-донорів допомоги.",
    "en": "Special attention is paid to providing our soldiers with medical supplies: saline solutions, painkillers, ethyl alcohol, adrenaline, isofilia, cardiovascular and gastrointestinal tablets, vitamins, warming gels and ointments, antiseptic ointments and solutions. In addition to retail medicine, we also provide fully equipped first aid kits according to military standards of donor countries."
  },
  "lang__zsu-list-title-4" : {
    "ua": "Тактика та інше обладнання",
    "en": "Tactical and Other Equipment"
  },
  "lang__zsu-list-description-4" : {
    "ua": "Завдяки підтримці вітчизняних та закордонних організацій та приватних осіб нами закуплено та передано нашим військовим: дрони, рації, намети, термобілизну, лопати саперні, буржуйки, генератори бензинові, олива для генераторів, тепловізори, планшети, спальники, джерела безперебійного живлення, їжу, сухі душі, масло для зброї, обігрівачі, сонячні панелі та батареї до них, газові пальники.",
    "en": "Thanks to the support of domestic and foreign organizations and private individuals, we have purchased and transferred to our military: drones, radios, tents, thermal underwear, sapper shovels, stoves, gasoline generators, generator oil, thermal imagers, tablets, sleeping bags, uninterruptible power supplies, food, dry showers, weapon oil, heaters, solar panels and batteries, gas burners."
  },
  "lang__zsu-list-title-5" : {
    "ua": "Військовим медикам",
    "en": "Combat Medics"
  },
  "lang__zsu-list-description-5" : {
    "ua": "Колаборація з вітчизняними фарм. компаніями, закордонними доброчинними організаціями та приватними особами дозволяє нам забезпечувати військових медиків на стабілізаційних пунктах та медичних евакуаційних екіпажах найнеобхіднішими медикаментами та виробами медичного призначення для збереження життів поранених: йоностеріл, гелафундін, транексам, дексаметазон, ондансетрон, лідокаїн, бупівакаїн, моксіфлоксацин, ЦВК різних розмірів, заглушки для в/в катетерів, тегадерм адванс в/в, канюлі міні спайк, вазофікси різних розмірів, дренажі торакальні, сечозбірники, набори для катетерізації сечового міхура, катетери сечові типу Фоллей, пов’язки окклюзійні, монітори пацієнта Mindray, набори для ПХО ран одноразові стерильні, стерильні рукавички, хірургічні голки, кисневі маски, польові ноші, кишеньковий міні-ехограф Wi-Fi.",
    "en": "Collaboration with domestic pharmaceutical companies, foreign charitable organizations, and private individuals allows us to provide military medics at stabilization points and medical evacuation crews with the most necessary medications and medical devices to save the lives of the wounded: ionosteril, gelafundin, tranexam, dexamethasone, ondansetron, lidocaine, bupivacaine, moxifloxacin, CVCs of various sizes, IV catheter plugs, tegaderm advance IV, mini spike cannulas, vasofix of various sizes, thoracic drains, urine collectors, bladder catheterization kits, Foley catheters, occlusive dressings, Mindray patient monitors, single-use sterile wound care kits, sterile gloves, surgical needles, oxygen masks, field stretchers, pocket mini-ultrasound Wi-Fi."
  },
  "lang__zsu-list-title-6" : {
    "ua": "На шпиталь",
    "en": "Hospitals aid"
  },
  "lang__zsu-list-description-6" : {
    "ua": "Завдяки колаборації з українською мережею магазинів <span class=\"text-uppercase\">Humana</span>, нами передано пораненим у шпиталі та реабілітаційні центри: майки, футболки, сорочки, шорти, штани, кофти, светри, світшоти. Також невелику партію адаптивного одягу від компанії PHY нами було передано пораненим в шпиталь. Ще на військові шпиталі передаємо медицину: шприци та голки для ін’єкцій, шприци з фізрозчином, системи для змішування ліків для ін’єкцій, голки для забору крові, системи до крапельниць, зонди, комплектуючі для кало- та уріностом, ліки, мазі, медичні маски, медичний одяг, поїлки, дезінфектори, стерильні накладки на рани, бинти різного типу, накладки на очі і ще всілякі приспособи для хірургії та реанімації.",
    "en": "Thanks to collaboration with the Ukrainian Humana store network, we have delivered to hospitals and rehabilitation centers for the wounded: t-shirts, shirts, shorts, pants, sweatshirts, sweaters. We also delivered a small batch of adaptive clothing from PHY to the hospital. Additionally, we deliver medical supplies to military hospitals: syringes and needles for injections, saline syringes, medicine mixing systems for injections, blood collection needles, IV systems, probes, components for colostomy and urostomy, medications, ointments, medical masks, medical clothing, drinkers, disinfectants, sterile wound pads, various types of bandages, eye pads, and various surgical and resuscitation aids."
  },
  "lang__zsu-list-title-7" : {
    "ua": "Необхідне",
    "en": "Essentials"
  },
  "lang__zsu-list-description-7" : {
    "ua": "Що зараз <span class=\"text-uppercase\">необхідне</span>, чим ще можна допомогти нашим воїнам? Необхідним і важливим є забезпечення поранених одягом. Коли поранений потрапляє на медичний стабілізаційний пункт, як правило, з нього зрізається вся його одежа. І що далі? Далі, після стабілізації бійця, його потрібно вдягнути в елементарні речі – білизну (в тому числі адаптивну), сорочки, штани, капці… Мати у що вдягнути пораненого – це про частину його життя, частину його відновлення, це про гідність та турботу, це про <span class=\"text-uppercase\">необхідне</span>. Тому ми розпочали цей проект і, разом з нашими друзями та партнерами – дівчатами з волонтерської групи Шити-Жити, шиємо білизну та забезпечуємо нею поранених на стабілізаційних пунктах та у шпиталях. Частково тканину для цього нам безкоштовно надає Алла Березовська, підприємець з м. Горішні Плавні.",
    "en": "What do our soldiers <span class=\"text-uppercase\">essentials</span>, and how else can we help them? It is crucial to provide the wounded with clothing. When a wounded soldier arrives at a medical stabilization point, their clothing is often cut off. What’s next? After stabilizing the fighter, they need basic items - underwear (including adaptive), shirts, pants, slippers... Having clothes for the wounded is part of their life, part of their recovery, it's about dignity and care, it's about <span class=\"text-uppercase\">essentials</span>. That’s why we started this project, and together with our friends and partners - the girls from the Shiti-Zhiti volunteer group, we sew underwear and provide it to the wounded at stabilization points and in hospitals. Part of the fabric for this is provided to us free of charge by Alla Berezovska, an entrepreneur from Horishni Plavni."
  },
  "lang__zsu-list-title-8" : {
    "ua": "Адаптивний вже допомагає",
    "en": "Adaptive Cloth Already Helps"
  },
  "lang__zsu-list-description-8" : {
    "ua": "Адаптивний – специфічний одяг для поранених, що має липучки та зав'язки замість швів і тому його зручно вдягати, носити, знімати та знову вдягати на перев'язках. Це єдиний одяг, який легко можуть використовувати наші захисники зі складними пораненнями. Ми, разом з волонтерською групою Шити-Жити, забезпечуємо наших поранених адаптивним одягом: майками, футболками, шортами, штанами. Для цього ми організовуємо збори коштів, закуповуємо трикотажну тканину, а дівчата з Шити-Жити його шиють. Зрештою, наш адаптивний одяг дістається до стабілізаційних пунктів та шпиталів де допомагає воїнам.",
    "en": "Adaptive clothing is specific clothing for the wounded that has velcro and ties instead of seams, making it easy to put on, wear, remove, and put on again during dressing changes. It is the only clothing that can be easily used by our defenders with complex wounds. Together with the Shiti-Zhiti volunteer group, we provide our wounded with adaptive clothing: t-shirts, shorts, pants. For this, we organize fundraisers, purchase knitwear, and the girls from Shiti-Zhiti sew it. Ultimately, our adaptive clothing reaches stabilization points and hospitals where it helps the warriors."
  }
}