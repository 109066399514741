import './js/animation-elements';

import './js/multi-languages-app';

import './js/mobile-menu';

import './js/scroll-to-footer';

import './js/correspondent-banks';

import './js/scroll-up';