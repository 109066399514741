{
  "lang__menu-link-home" : {
    "ua": "<span class=\"text-uppercase\">\"Заново\"</span>",
    "en": "<span class=\"text-uppercase\">\"Renovate\"</span>"
  },
  "lang__menu-link-projects" : {
    "ua": "Проєкти",
    "en": "Projects"
  },
  "lang__menu-link-about" : {
    "ua": "Про нас",
    "en": "About us"
  },
  "lang__menu-link-report" : {
    "ua": "Звіти і Подяки",
    "en": "Reports and Thanks"
  },
  "lang__menu-link-contacts" : {
    "ua": "Контакти",
    "en": "Contacts"
  },
  "lang__menu-link-donate" : {
    "ua": "Зробити донат",
    "en": "Donation"
  },

  "lang__menu-sub-link-zsu-help" : {
    "ua": "Допомога ЗСУ",
    "en": "Help to AFU"
  },
  "lang__menu-sub-link-reconstruction" : {
    "ua": "Відбудова країни",
    "en": "Reconstruction"
  },
  "lang__menu-sub-link-nets" : {
    "ua": "Загін СМС",
    "en": "Camouflage forces"
  },
  "lang__menu-sub-link-candle" : {
    "ua": "Сили ССО",
    "en": "Candle forces"
  },
  "lang__menu-sub-link-humanitarian" : {
    "ua": "Гуманітарна допомога",
    "en": "Humanitarian aid"
  },
  "lang__menu-sub-link-events" : {
    "ua": "Благодійні заходи",
    "en": "Charity events"
  }
}