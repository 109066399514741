{
  "lang__humanitarian-page-title" :  {
    "ua": "БФ \"ЗАНОВО\". Гуманітарна допомога.",
    "en": "RENOVATE CHARITY. Project \"Humanitarian aid\"."
  },
  "lang__humanitarian-title" : {
    "ua": "Гуманітарна допомога",
    "en": "Humanitarian aid"
  },
  "lang__humanitarian-description-1-1" : {
    "ua": "Допомагаємо відновлювати життя мешканцям деокупованих районів Чернігівщини та Харківщини. Передаємо проднабори, гігієну, одяг, будівельні матеріали та інструменти. Підтримуємо людей які, попри всі складнощі сьогодення, відновлюють свої домівки та продовжують жити на своїй рідній землі.",
    "en": "Helping Residents of De-Occupied Areas of Chernihiv and Kharkiv Regions. We assist residents in de-occupied areas of Chernihiv and Kharkiv regions to restore their lives. We provide food packages, hygiene products, clothing, building materials, and tools. We support people who, despite all current difficulties, are restoring their homes and continuing to live on their native land."
  },
  "lang__humanitarian-description-1-2" : {
    "ua": "В підтримці один одного наша сила! Не зупиняємось!",
    "en": "Strength in Supporting Each Other! We Keep Going!"
  },
  "lang__humanitarian-description-2-1" : {
    "ua": "Завезли гуманітарну допомогу до Білоцерківського неврологічного інтернату. Передали: ковдри, пледи, постільну білизну, подушки, дитячі пюре, сухі дитячі каші, памперси, пелюшки для дорослих, гелі для душу, шампуні, зубні пасти та щітки, мочалки для душу, вологі серветки, мило, креми для рук, маски одноразові, антисептики.",
    "en": "We delivered humanitarian aid to the Bila Tserkva Neurological Boarding School. We provided blankets, bedding, pillows, baby puree, dry baby cereals, diapers, adult pads, shower gels, shampoos, toothpaste and brushes, sponges, wet wipes, soap, hand creams, disposable masks, and antiseptics."
  },
  "lang__humanitarian-description-2-2" : {
    "ua": "Закупили та відправити таке надважливе на сьогодні джерело безперебійного живлення (ДБЖ Stark Pro 3/1-10), а також надпотужний генератор Hyundai HY 13000LE до ДУ \"Національний Інститут терапії імені Л.Т. Малої НАМН України\" у Харкові.",
    "en": "We purchased and sent an uninterruptible power supply (UPS Stark Pro 3/1-10) and a powerful Hyundai HY 13000LE generator to the National Institute of Therapy named after L.T. Malaya of the National Academy of Medical Sciences of Ukraine in Kharkiv."
  },
  "lang__humanitarian-description-3-1" : {
    "ua": "Ольга Гришнюк не втомлюється опікуватись дітками з особливими потребами, надаючи їм таку необхідну допомогу мед. препаратами та спец. харчуванням.",
    "en": "Olha Hryshniuk tirelessly cares for children with special needs, providing them with necessary medical supplies and special nutrition."
  },
  "lang__humanitarian-description-3-2" : {
    "ua": "Ми приєднались до цієї справи: передаємо для них медикаменти, які вони вимушені приймати щодня, дитяче харчування та засоби гігієни.",
    "en": "We joined this effort by providing medications they need to take daily, baby food, and hygiene products."
  },
  "lang__humanitarian-description-4-1" : {
    "ua": "Допомагаємо дітям адаптуватись до життя в умовах війни. Проводимо майстер-класи з випікання смаколиків для діток-біженців в Києві. Влаштовуємо свято Миколая для школярів у Борівському академічному ліцеї Фастівського району Київщини. Допомагаємо дітям в сім’ях переселенців з книжками, одягом та іграшками.",
    "en": "Helping Children Adapt to Life in War Conditions. We organize master classes for baking treats for refugee children in Kyiv. We organize Saint Nicholas Day celebrations for students at Boryspil Academic Lyceum in the Fastiv district of the Kyiv region. We help children in displaced families with books, clothes, and toys."
  },
  "lang__humanitarian-description-4-2" : {
    "ua": "Нести добро та світ дітям, попри війну та втрати, це місія дорослих!",
    "en": "Bringing Kindness and Light to Children Despite War and Losses is the Mission of Adults!"
  }
}