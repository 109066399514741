{
  "lang__reconstruction-page-title" :  {
    "ua": "БФ \"ЗАНОВО\". Відбудова країни.",
    "en": "RENOVATE CHARITY. Project \"Country Reconstruction\""
  },
  "lang__reconstruction-title" : {
    "ua": "Відбудова країни",
    "en": "Country Reconstruction"
  },
  "lang__reconstruction-list-title-1" : {
    "ua": "Відновлюємо інклюзивно-ресурсний центр у <br> смт Бородянка Київської області",
    "en": "Restoring the Inclusive Resource Center <br> in Borodyanka, Kyiv Region"
  },
  "lang__reconstruction-list-description-1" : {
    "ua": "(червень-липень 2023)",
    "en": "(June-July 2023)"
  },
  "lang__reconstruction-description-1" : {
    "ua": "У травні 2022 року ми відновили інклюзивно-ресурсний центр у Бородянці, який був пошкоджений внаслідок російських бомбардувань. Залучивши ресурси та волонтерів, ми відремонтували дах, стіни, стелю та замінили двері, щоб діти з особливими потребами знову мали доступ до необхідної допомоги.",
    "en": "In May 2022, we restored the Inclusive Resource Center in Borodyanka, damaged by Russian bombings. We repaired the roof, walls, ceiling, and replaced the doors, allowing children with special needs to access necessary assistance again."
  },
  "lang__reconstruction-list-title-2" : {
    "ua": "Відновлюємо паспортний стіл у смт Бородянка Київської області",
    "en": "Restoring the Passport Office in Borodyanka, Kyiv Region"
  },
  "lang__reconstruction-list-description-2" : {
    "ua": "(травень 2022)",
    "en": "(May 2022)"
  },
  "lang__reconstruction-description-2" : {
    "ua": "У травні 2022 року ми допомогли відновити паспортний стіл у Бородянці, який був пошкоджений під час бойових дій. Ми не тільки відремонтували приміщення, але й забезпечили його новими меблями та оргтехнікою, щоб громадяни могли швидко отримувати необхідні документи.",
    "en": "In May 2022, we helped restore the passport office in Borodyanka, damaged during the fighting. We not only repaired the premises but also provided new furniture and office equipment to ensure citizens could quickly obtain necessary documents."
  },
  "lang__reconstruction-list-title-3" : {
    "ua": "Відновлюємо дитячий садок \"Казка\" у смт Бородянка Київської області",
    "en": "Restoring the \"Kazka\" Kindergarten in Borodyanka, Kyiv Region"
  },
  "lang__reconstruction-list-description-3" : {
    "ua": "(червень-липень 2022)",
    "en": "(June-July 2022)"
  },
  "lang__reconstruction-description-3" : {
    "ua": "У червні-липні 2022 року ми відновили дитячий садок \"Казка\" у Бородянці, який був пошкоджений російськими окупантами. Завдяки благодійним внескам та волонтерській допомозі, ми замінили вибиті двері та вікна, щоб діти могли повернутися до безпечного і комфортного навчання.",
    "en": "In June-July 2022, we restored the \"Kazka\" kindergarten in Borodyanka, damaged by Russian occupiers. Thanks to charitable donations and volunteer help, we replaced broken doors and windows, ensuring children could return to a safe and comfortable learning environment."
  },
  "lang__reconstruction-list-title-4" : {
    "ua": "Відновлюємо середній заклад освіти школу-дитячий садок \"Схід\" у м. Києві",
    "en": "Restoring the \"Skhid\" School-Kindergarten in Kyiv"
  },
  "lang__reconstruction-list-description-4" : {
    "ua": "(з липня 2022)",
    "en": "(from July 2022)"
  },
  "lang__reconstruction-description-4" : {
    "ua": "З липня по листопад 2022 року ми відновлювали школу-дитячий садок \"Схід\" у Києві, яка постраждала від вибухової хвилі. Ми замінили вибиті вікна, щоб діти з особливими потребами могли продовжити своє навчання і розвиток у безпечних умовах. Завдяки вашій підтримці, ми створили три теплі кімнати та їдальню, де діти можуть займатися і відпочивати.",
    "en": "From July to November 2022, we restored the \"Skhid\" school-kindergarten in Kyiv, damaged by an explosive wave. We replaced broken windows, ensuring children with special needs could continue their education and development in safe conditions. Thanks to your support, we created three warm rooms and a dining area where children can learn and relax."
  }
}