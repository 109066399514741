{
  "lang__candle-page-title" :  {
    "ua": "БФ \"ЗАНОВО\". Свічки.",
    "en": "RENOVATE CHARITY. Project \"Charity Events\""
  },
  "lang__candle-title" : {
    "ua": "Сили спеціальних свічкових операцій",
    "en": "Forces of Special Candle Operations"
  },
  "lang__candle-description-1" : {
    "ua": "В нашому фонді в січні 2023 р. утворилися <span class=\"text-uppercase\">сили спеціальних свічкових операцій</span> – справжній спецназ з питань окопних свічок.",
    "en": "In January 2023, our foundation established the <span class=\"text-uppercase\">Forces of Special Candle Operations</span> – a real special unit for trench candles."
  },
  "lang__candle-description-2" : {
    "ua": "Дівчата і хлопці завзято крутять і заливають свічки. За зиму вони накрутили 12701 штук окопних свічок, які обігрівають наших захисників на всіх напрямках фронту.",
    "en": "The team eagerly rolls and pours candles. Over the winter, they made 12,701 trench candles, which keep our defenders warm on all fronts."
  }
}